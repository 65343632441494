<template>
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
    <h4>Payment Request</h4>
    <div class="card request-list-table table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Amount</th>
            <th>payment Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="payment.length>0">
            <td class="requester-name pl-5">12 March 2020</td>
            <td class="wedding-date pl-5">$80</td>
            <td class="requester-id text-success text-danger pl-5">Pending</td>
            <td class="requester-id text-success pl-5">
              <div id="slide-panel5" class="slide-panel-light" style="position: fixed; width: 275px; transition: all 450ms ease 0s; height: 100%; top: 0px; right: -275px; z-index: 1049;">
                <h3>Change Payment Status</h3>
                <form>
                  <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-6 col-6">
                      <h6>Amount</h6>
                    </div>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pt-0 pb-0">
                      <div class="form-group">
                        <v-text-field name="vendorname" type="text" placeholder="$ " v-model="payment.amount" outlined></v-text-field>
                      </div>
                    </div>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-6 col-6 pt-0">
                      <h6>Change Status</h6>
                    </div>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pt-0 pb-0">
                      <div class="form-group">
                        <v-select :items="status" item-text="text" item-value="value" v-model="payment.status" outlined></v-select>
                      </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                      <div class="form-group">
                        <a v-on:click="savePayment" class="btn btn-success text-white">Save</a>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <a v-on:click.prevent="openSlider()" class="btn btn-default btn-xs" id="trigger5">Release payment</a>
            </td>
          </tr>
          <tr v-if="payment.length == 0">
            <td></td>
            <td><div class="empty-data">No payment request</div></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
    <h4>Payout Transaction History</h4>
    <div class="card request-list-table table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Amount</th>
            <th>payment Status</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="payment_history.length > 0">
            <td class="requester-name pl-5">12 March 2020</td>
            <td class="wedding-date pl-5">$80</td>
            <td class="requester-id text-success pl-5">Paid</td>
          </tr>
          <tr v-if="payment_history.length==0">
            <td></td>
            <td>
              <div class="empty-data text-center">No payment request</div>
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name:'Tutor-Payment',
  props:['tutor'],
  data(){
    return{
      payment:null,
      payment_history:null,
      status:[
        {value:1,text:'Paid'},
        {value:2,text:'Pending'}
      ],
    }
  },
  beforeMount(){
    axios.get(process.env.VUE_APP_TUTION_SITE_API+'/tutorpayments/'+this.tutor.tutor_id,{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
      if(res.data.message == 'Tutor payment retrived successfully '){
        this.payment_history = res.data.data.history;
        this.payment = res.data.data.pendingRequest;
      }
    })
  },
  methods:{
    openSlider(){
      document.getElementById('slide-panel5').style.right = '0px';
      document.getElementById('slide-panel5').style.width = '275px';
      document.getElementById('slide-reveal-overlay').style.display = 'block';
    },
    closeSlideBar(){
      document.getElementById('slide-panel5').style.right = '-275px';
      document.getElementById('slide-panel5').style.width = '0px';
      document.getElementById('slide-reveal-overlay').style.display = 'none';
    },
    savePayment(e){
      e.preventDefault();
      console.log(this.payment);
      this.closeSlideBar();
    }
  }
}
</script>

<style scoped>
.request-list-table .table {
  font-size: 14px;
}
.request-list-table table th {
  background-color: #fff;
  font-weight: 400;
  border-bottom: 1px solid #eee;
  padding: 14px 20px;
  color: #252328;
}
.request-list-table table td {
    background-color: #fdfdfd;
    border-bottom: 1px solid #eee;
    padding: 10px 16px;
}
.table > :not(caption) > * > * {
    padding: 0.5rem 0.5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
table {
    color: #6f6d72;
}
.request-list-table table td{
    background-color: #fdfdfd;
    border-bottom: 1px solid #eee;
    padding: 10px 16px;
}
.slide-panel-light {
    background-color: #fff;
    padding: 30px;
    text-align: left;
    overflow: scroll;
}
</style>